import React from 'react'

import PartnerImg1 from '../../assets/images/partner-img-geoml/psi-logo-h100px-gray.png'
import PartnerHoverImg1 from '../../assets/images/partner-img-geoml/psi-logo-h100px.png'
import PartnerImg2 from '../../assets/images/partner-img-geoml/eurad-logo-h100px-gray.png'
import PartnerHoverImg2 from '../../assets/images/partner-img-geoml/eurad-logo-h100px.png'
import PartnerImg3 from '../../assets/images/partner-img-geoml/predis-logo-h100px-gray.png'
import PartnerHoverImg3 from '../../assets/images/partner-img-geoml/predis-logo-h100px.png'
import PartnerImg4 from '../../assets/images/partner-img-geoml/nrg-logo-h100px-gray.png'
import PartnerHoverImg4 from '../../assets/images/partner-img-geoml/nrg-logo-h100px.png'

// import PartnerImg1 from '../../assets/images/partner-img-geoml/partner-01-h200px.png'
// import PartnerHoverImg1 from '../../assets/images/partner-img-geoml/partner-01-hover-h200px.png'
// import PartnerImg2 from '../../assets/images/partner-img-geoml/partner-02-h200px.png'
// import PartnerHoverImg2 from '../../assets/images/partner-img-geoml/partner-02-hover-h200px.png'
// import PartnerImg3 from '../../assets/images/partner-img-geoml/partner-03-h200px.png'
// import PartnerHoverImg3 from '../../assets/images/partner-img-geoml/partner-03-hover-h200px.png'
// import PartnerImg4 from '../../assets/images/partner-img-geoml/partner-04-h200px.png'
// import PartnerHoverImg4 from '../../assets/images/partner-img-geoml/partner-04-hover-h200px.png'

// import PartnerImg5 from '../../assets/images/partner-img-geoml/partner-05.png'
// import PartnerHoverImg5 from '../../assets/images/partner-img-geoml/partner-05-hover.png'
// import PartnerImg6 from '../../assets/images/partner-img-geoml/partner-06.png'
// import PartnerHoverImg6 from '../../assets/images/partner-img-geoml/partner-06-hover.png'
// import PartnerImg7 from '../../assets/images/partner-img-geoml/partner-07.png'
// import PartnerHoverImg7 from '../../assets/images/partner-img-geoml/partner-07-hover.png'
// import PartnerImg8 from '../../assets/images/partner-img-geoml/partner-08.png'
// import PartnerHoverImg8 from '../../assets/images/partner-img-geoml/partner-08-hover.png'

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
  loop: true,
  nav: false,
  dots: false,
  // autoplay: true,
  autoplay: false,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      // items: 2
      items: 1
    },
    768: {
      // items: 4
      items: 2
    },
    1200: {
      // items: 6
      items: 4
    }
  }
}

const PartnerStyleTwo = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className='repair-partner-area bg-f9fafb'>
      <div className='container'>
        {display ? ( // eslint-disable-line
          <OwlCarousel
            className='repair-partner-slides owl-carousel owl-theme'
            {...options}
          >
            <div className='single-repair-partner'>
              <a
                title={
                  'Laboratory for Waste Management (LES) at' +
                  '\n' +
                  'Paul Scherrer Institut (PSI)'
                }
                href='https://www.psi.ch/en/les'
                target='_blank'
                rel='noreferrer'
              >
                <img src={PartnerImg1} alt='logo PSI' />
                <img src={PartnerHoverImg1} alt='logo PSI' />
              </a>
            </div>

            <div className='single-repair-partner'>
              <a
                title='European Joint Programme on Radioactive Waste Management (EURAD)'
                href='https://www.ejp-eurad.eu/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={PartnerImg2} alt='logo EURAD' />
                <img src={PartnerHoverImg2} alt='logo EURAD' />
              </a>
            </div>

            <div className='single-repair-partner'>
              <a
                title='Pre-disposal management of radioactive waste (PREDIS)'
                href='https://predis-h2020.eu/predis-project/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={PartnerImg3} alt='logo PREDIS' />
                <img src={PartnerHoverImg3} alt='logo PREDIS' />
              </a>
            </div>

            <div className='single-repair-partner'>
              <a
                title='An internationally-operating nuclear service provider'
                href='https://www.nrg.eu/en'
                target='_blank'
                rel='noreferrer'
              >
                <img src={PartnerImg4} alt='logo NRG' />
                <img src={PartnerHoverImg4} alt='logo NRG' />
              </a>
            </div>

            {/* <div className='single-repair-partner'>
              <a href='https://congineer.com' target='_blank' rel='noreferrer'>
                <img src={PartnerImg5} alt='image' />
                <img src={PartnerHoverImg5} alt='image' />
              </a>
            </div> */}

            {/* <div className='single-repair-partner'>
              <a href='https://congineer.com' target='_blank' rel='noreferrer'>
                <img src={PartnerImg6} alt='image' />
                <img src={PartnerHoverImg6} alt='image' />
              </a>
            </div> */}

            {/* <div className='single-repair-partner'>
              <a href='https://congineer.com' target='_blank' rel='noreferrer'>
                <img src={PartnerImg7} alt='image' />
                <img src={PartnerHoverImg7} alt='image' />
              </a>
            </div> */}

            {/* <div className='single-repair-partner'>
              <a href='https://congineer.com' target='_blank' rel='noreferrer'>
                <img src={PartnerImg8} alt='image' />
                <img src={PartnerHoverImg8} alt='image' />
              </a>
            </div> */}
          </OwlCarousel>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default PartnerStyleTwo
