import React from 'react'
// import { Link } from 'gatsby'
import MailTo from './MailTo'

const Cta = () => {
  return (
    <div className='cta-area ptb-80'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-7 col-md-6'>
            <div className='cta-content'>
              <h3>Sign up for using JupyterLab</h3>
            </div>
          </div>

          <div className='col-lg-5 col-md-6'>
            <div className='cta-right-content'>
              <div className='hosting-price'>
                <span>Write an email to</span>
                <h4>Coordinator</h4>
              </div>

              <div className='buy-btn'>
                {/* <Link to='/contact' className='btn btn-primary'>
                  Apply Now
                </Link> */}
                <MailTo
                  email='admin@geoml.eu'
                  subject='[geoml.eu] Sign up for using JupyterLab service'
                  body='Dear GeoML Coordinator!'
                >
                  <div className='btn btn-primary'>Apply Now</div>
                </MailTo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cta
