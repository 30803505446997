import React from 'react'

// Hosting Images
import HostingShape1 from '../../assets/images/hosting-banner-image/hosting-shape1.png'
import HostingShape2 from '../../assets/images/hosting-banner-image/hosting-shape2.png'
import HostingShape3 from '../../assets/images/hosting-banner-image/hosting-shape3.png'
import HostingShape4 from '../../assets/images/hosting-banner-image/hosting-shape4.png'
import HostingShape5 from '../../assets/images/hosting-banner-image/hosting-shape5.png'
import HostingShape6 from '../../assets/images/hosting-banner-image/hosting-shape6.png'
import HostingShape7 from '../../assets/images/hosting-banner-image/hosting-shape7.png'
import HostingShape8 from '../../assets/images/hosting-banner-image/hosting-shape8.png'
import HostingShape9 from '../../assets/images/hosting-banner-image/hosting-shape9.png'
import HostingStaticMain from '../../assets/images/hosting-banner-image/hosting-static-main.png'

// Shape Images
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'
import Shape3 from '../../assets/images/shape3.svg'
import Shape4 from '../../assets/images/shape4.svg'
import Shape5 from '../../assets/images/shape5.png'

const MainBanner = () => {
  return (
    <div className='hosting-main-banner'>
      <div className='d-table'>
        <div className='d-table-cell'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-12'>
                <div className='hosting-banner-content'>
                  <h1>GeoML.eu JupyterLab service</h1>

                  <ul>
                    <li>No computing resources? No problem!</li>
                    <li>No need to install any software on your device</li>
                    {/* <li>Easy to sign up and get started to work anywhere</li> */}
                    <li>JupyterHub will work via your web browser</li>
                    <li>Go into one of lab folders and open a notebook</li>
                    <li>Create your own lab folder and Python environment</li>
                    <li>Launch your advanced demo app on a Voila server</li>
                    <li>Various ways of collaboration are possible</li>
                  </ul>

                  <p>
                    <b>For participants of EURAD projects!</b>
                  </p>

                  <a
                    className='btn btn-primary'
                    href='https://hub.geoml.eu'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Login to our JupyterLab
                  </a>

                  <p>
                    <b>Predis Demo: Digital Twin</b>
                  </p>

                  <a
                    className='btn btn-info'
                    href='https://digitaltwin.geoml.eu'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Start Digital Twin Demo
                  </a>
                </div>
              </div>

              <div className='col-lg-6 col-md-12'>
                <div className='hosting-banner-image'>
                  <img
                    src={HostingShape1}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape2}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape3}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape4}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape5}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape6}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape7}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape8}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingShape9}
                    className='animate__animated animate__fadeInDown animate__delay-0.7s'
                    alt=''
                  />

                  <img
                    src={HostingStaticMain}
                    className='animate__animated animate__fadeInUp animate__delay-0.7s'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className='shape1'>
        <img src={Shape1} alt='shape' />
      </div>
      <div className='shape2 rotateme'>
        <img src={Shape2} alt='shape' />
      </div>
      <div className='shape3'>
        <img src={Shape3} alt='shape' />
      </div>
      <div className='shape4'>
        <img src={Shape4} alt='shape' />
      </div>
      <div className='shape5'>
        <img src={Shape5} alt='shape' />
      </div>
      <div className='shape6 rotateme'>
        <img src={Shape4} alt='shape' />
      </div>
      <div className='shape7'>
        <img src={Shape4} alt='shape' />
      </div>
      <div className='shape8 rotateme'>
        <img src={Shape2} alt='shape' />
      </div>
    </div>
  )
}

export default MainBanner
