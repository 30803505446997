import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
// import NavbarStyleTwo from '../components/_App/NavbarStyleTwo'
import Navbar from '../components/_App/Navbar'
import MainBanner from '../components/Home/MainBanner'
// import TopFeatures from '../components/Home/TopFeatures'
// import DomainSearch from '../components/Home/DomainSearch'
// import WhyChoose from '../components/Home/WhyChoose'
// import PricingStyleTwo from '../components/PricingPlans/PricingStyleTwo'
// import FeedbackStyleTwo from '../components/Common/FeedbackStyleTwo'
import Partner from '../components/Common/Partner'
// import Features from '../components/Home/Features'
// import BlogPost from '../components/Common/BlogPost'
import Cta from '../components/Common/Cta'
import Footer from '../components/_App/Footer'

const Home = () => (
  <Layout>
    <Seo title='GeoML.eu Home' />
    <Navbar />
    <MainBanner />
    {/* <TopFeatures /> */}
    {/* <DomainSearch /> */}
    {/* <WhyChoose /> */}
    {/* <PricingStyleTwo /> */}
    {/* <FeedbackStyleTwo /> */}
    {/* <div className='pb-80'>
      <Partner />
    </div> */}
    <Partner />
    {/* <Features /> */}
    {/* <BlogPost /> */}
    <Cta />
    <Footer />
  </Layout>
)

export default Home
